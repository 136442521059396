import React from 'react'
import { Link } from 'gatsby'

// ML Banner Images
import One from '../../assets/images/ml-banner-image/one.png'
import Two from '../../assets/images/ml-banner-image/two.png'
import Three from '../../assets/images/ml-banner-image/three.png'
import Four from '../../assets/images/ml-banner-image/four.png'
import Five from '../../assets/images/ml-banner-image/five.png'
import Six from '../../assets/images/ml-banner-image/six.png'
import Seven from '../../assets/images/ml-banner-image/seven.png'
import Eight from '../../assets/images/ml-banner-image/eight.png'
import Nine from '../../assets/images/ml-banner-image/nine.png'
import Ten from '../../assets/images/ml-banner-image/ten.png'
import Eleven from '../../assets/images/ml-banner-image/eleven.png'
import MLMainPic from '../../assets/images/ml-banner-image/ml-main-pic.png'

// Shape Images
import Shape1 from '../../assets/images/shape1.png'
import Shape2 from '../../assets/images/shape2.svg'
import Shape3 from '../../assets/images/shape3.svg'
import Shape4 from '../../assets/images/shape4.svg'

const MainBanner = () => {
  return (
    <div className='ml-main-section'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 col-md-12'>
            <div className='ml-banner-content'>
              <h1>GeoML: Geochemical modelling using Machine Learning</h1>
              <p>
                Machine Learning (ML) is a modern trend in science. In
                geochemical modelling, ML is used, for instance, in accelerating
                reactive transport simulations by training Neural Networks (NN)
                as a fast surrogate for the direct (but much slower) codes for
                computing chemical equilibria, such as PHREEQC or GEMS.
              </p>
              {/*
              <Link to='/contact' className='btn btn-secondary'>
                Get Started
              </Link> */}

              <p>
                <b>For participants of GeoML.ai projects!</b>
              </p>

              <a
                className='btn btn-primary'
                href='https://hub.geoml.ai'
                target='_blank'
                rel='noreferrer'
              >
                Login to JupyterLab service
              </a>

              <p>
                <b>{}</b>
              </p>

              <p>
                <b>Voila Demo: GeoML Interactive</b>
              </p>

              <a
                className='btn btn-info'
                href='https://neuralnetrt.geoml.ai'
                target='_blank'
                rel='noreferrer'
              >
                Start Interactive ML Demo
              </a>
            </div>
          </div>

          <div className='col-lg-6 col-md-12'>
            <div className='ml-banner-image'>
              <img
                src={One}
                className='animate__animated animate__fadeIn animate__delay-2s'
                alt='image'
              />

              <img
                src={Two}
                className='animate__animated animate__fadeInUp animate__delay-1s'
                alt='image'
              />

              <img
                src={Three}
                className='animate__animated animate__fadeInUp animate__delay-0.5s'
                alt='image'
              />

              <img
                src={Four}
                className='animate__animated animate__fadeInUp animate__delay-2s'
                alt='image'
              />

              <img
                src={Five}
                className='animate__animated animate__fadeInDown animate__delay-2.5s'
                alt='image'
              />

              <img
                src={Six}
                className='animate__animated animate__fadeInDown animate__delay-1.9s'
                alt='image'
              />

              <img
                src={Seven}
                className='animate__animated animate__fadeInDown animate__delay-2.1s'
                alt='image'
              />

              <img
                src={Eight}
                className='animate__animated animate__fadeInDown animate__delay-1.9s'
                alt='image'
              />

              <img
                src={Nine}
                className='animate__animated animate__fadeInDown animate__delay-1.7s'
                alt='image'
              />

              <img
                src={Ten}
                className='animate__animated animate__fadeInDown animate__delay-1.5s'
                alt='image'
              />

              <img
                src={Eleven}
                className='animate__animated animate__fadeInUp animate__delay-0.4s'
                alt='image'
              />

              <img
                src={MLMainPic}
                className='animate__animated animate__fadeInUp animate__delay-0.5s'
                alt='image'
              />
            </div>
          </div>
        </div>
      </div>

      {/* Shape Images */}
      <div className='shape1'>
        <img src={Shape1} alt='shape' />
      </div>
      <div className='shape2 rotateme'>
        <img src={Shape2} alt='shape' />
      </div>
      <div className='shape3'>
        <img src={Shape3} alt='shape' />
      </div>
      <div className='shape4'>
        <img src={Shape4} alt='shape' />
      </div>
      <div className='shape7'>
        <img src={Shape4} alt='shape' />
      </div>
      <div className='shape8 rotateme'>
        <img src={Shape2} alt='shape' />
      </div>
    </div>
  )
}

export default MainBanner
