import React from 'react'
import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import Navbar from '../components/_App/Navbar'
import MainBanner from '../components/Home/MainBanner'
// import Partner from '../components/Home/Partner'
// import WhatWeOffer from '../components/Home/WhatWeOffer'
// import AboutUsContent from '../components/Home/AboutUsContent'
// import OurServices from '../components/Home/OurServices'
// import FunFactsArea from '../components/Common/FunFactsArea'
// import Projects from '../components/Home/Projects'
// import FeedbackStyleFour from '../components/Common/FeedbackStyleFour'
// import BlogCard from '../components/Home/BlogCard'
// import Newsletter from '../components/Common/Newsletter'
import Cta from '../components/Common/Cta'
import Footer from '../components/_App/Footer'

const Home = () => (
  <Layout>
    <Seo title='GeoML.ai Home' />
    <Navbar />
    <MainBanner />
    {/* <Partner /> */}
    {/* <WhatWeOffer /> */}
    {/* <AboutUsContent /> */}
    {/* <OurServices /> */}
    {/* <FunFactsArea /> */}
    {/* <Projects /> */}
    {/* <FeedbackStyleFour /> */}
    {/* <BlogCard /> */}
    {/* <Newsletter /> */}
    <Cta />
    <Footer />
  </Layout>
)

export default Home
